import { Carousel } from "@wbly/ui";
import { useMemo, useEffect, useState, type FC } from "react";
import type { FormattedItem } from "@wbly/data-storefront";
import styles from "./RangeRail.module.css";
import { ArrowRight } from "./icons/ArrowRight";
import { ArrowLeft } from "./icons/ArrowLeft";
import { trackRangeRailClick } from "./track-range-rail-events";

type RangeRailProps = {
  title: string;
  id: string;
  items: FormattedItem[];
};

const { anchor, pill, card, cardImage } = styles;

const Pill = ({ children, url }) => {
  return (
    <>
      <div className={pill}>{children}</div>
      <a className={anchor} href={url}></a>
    </>
  );
};

const Card = ({ label, image, url, onClick }) => {
  return (
    <div onClick={onClick} className={card}>
      <img className={cardImage} src={image} alt={label} />
      <Pill url={url}>{label}</Pill>
    </div>
  );
};

export const RangeRail: FC<RangeRailProps> = ({ items, title, id }) => {
  const slides = useMemo(
    () =>
      items.map((item, index) => {
        return (
          <Carousel.Slide key={item.title}>
            <Card
              onClick={() => trackRangeRailClick(id, index + 1)}
              label={item.title}
              image={item.image.url}
              url={item.url}
            />
          </Carousel.Slide>
        );
      }),
    [items, id]
  );

  const [displayAffix, setDisplayAffix] = useState(false);

  useEffect(() => {
    // TODO maybe consider doing this better in the long term as we
    // have an extra re-render here. CSS only solution would just be better
    const updateDisplayAffix = () => {
      const windowWidth = window.innerWidth;
      const shouldDisplay =
        (windowWidth <= 1039 && slides.length > 2) ||
        (windowWidth > 1039 && slides.length > 4);
      setDisplayAffix(shouldDisplay);
    };

    // Initial check
    updateDisplayAffix();

    // Update on resize
    window.addEventListener("resize", updateDisplayAffix);
    return () => window.removeEventListener("resize", updateDisplayAffix);
  }, [slides]);

  const breakpoints = {
    777: {
      animationDuration: 300,
      dragThreshold: false,
      swipeThreshold: 40,
      perView: 2,
    },
    1039: {
      animationDuration: 300,
      dragThreshold: false,
      swipeThreshold: 40,
      perView: 3,
    },
  };

  return (
    <Carousel
      title={title}
      id={id}
      type="slider"
      perView={4}
      gap={10}
      peek={{
        before: 0,
        after: 30,
      }}
      displayAffix={displayAffix}
      breakpoints={breakpoints}
    >
      <Carousel.Header title={title} />
      <Carousel.Affix action="next" block>
        <ArrowRight color="#000" />
      </Carousel.Affix>
      <Carousel.Affix action="prev" block>
        <ArrowLeft color="#000" />
      </Carousel.Affix>
      {slides}
    </Carousel>
  );
};
