import React from "react";

export const ArrowLeft = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none">
    <g clipPath="url(#a)">
      <path
        fill={color}
        fillRule="evenodd"
        d="m6.724 13.58.646-.652-5.62-5.676h18.098v-.924H1.75L7.37.653 6.723 0 0 6.791l6.724 6.79Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={color} d="M19.848 13.58H0V0h19.848z" />
      </clipPath>
    </defs>
  </svg>
);
