import React from "react";

export const ArrowRight = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" fill="none">
    <g clipPath="url(#a)">
      <path
        fill={color}
        fillRule="evenodd"
        d="m13.908 0-.647.652 5.62 5.676H.785v.924h18.097l-5.62 5.675.647.653 6.724-6.79L13.908 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={color} d="M.784 0h19.848v13.58H.784z" />
      </clipPath>
    </defs>
  </svg>
);
