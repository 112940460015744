import { pushEvent } from "@wbly/common";
import type { DataLayer } from "@wbly/common";

export const trackRangeRailClick = (
  rangeRailRowId: string,
  rangeRailCardPosition: number
) => {
  //TODO: implement type in shared common function.

  // Doing this as a futureproof measure in case the dataLayer object changes.
  // The type is set to "unknown page type" as a fallback.
  const { type } = (window?.dataLayer as DataLayer[])

    // In order to isolate the object containing the "type"
    // we look for the object that contains the "app" key (there will only be one).
    // We don't use "type" as it is a common word and could be found in other objects.
    .find((obj) => Object.hasOwn(obj, "app"));

  pushEvent(
    window?.dataLayer as DataLayer[],
    `${type}`,
    `${rangeRailRowId}`,
    `Card position: ${rangeRailCardPosition}`
  );
};
